import React from 'react';
import Layout from '../components/Layout';
import ModelCanvas from '../components/ModelCanvas';
import Button from '../components/Button';

export default function indexPage() {
  const isBrowser = typeof window !== 'undefined';

  return (
    <Layout fullMenu>
      <article id="main">
        <header>
          <h2>Double Aortic Arch</h2>
        </header>
        <section className="wrapper style5">
          <div className="inner model">
            <section>
              <div className="row">
                <div className="col-6 col-12-medium">
                  <h2>Double Aortic Arch</h2>
                  <p>
                    is an anomaly of the aortic arch in which two aortic arches
                    form a complete vascular ring that can compress the trachea
                    and/or esophagus. Most commonly there is a larger (dominant)
                    right arch behind and a smaller (hypoplastic) left aortic
                    arch in front of the trachea/esophagus. The two arches join
                    to form the descending aorta which is usually on the left
                    side (but may be right-sided or in the midline).
                  </p>
                </div>
                {isBrowser && (
                  <div className="col-6 col-12-medium">
                    <ModelCanvas
                      model_path={'/double-aortic-arch.glb'}
                      camera_position={[5, 5, 5]}
                      light_intensity={0.3}
                      group_position={[0.1, -0.8, 0.0]}
                      group_rotation={[0, 0, 0]}
                      group_scale={[11, 11, 11]}
                    />
                  </div>
                )}
              </div>
            </section>
            <div>
              <br />
              <ul className="actions stacked">
                <li>
                  <br />
                  <Button
                    buttonClass="button small primary"
                    url="https://biovoxel.tech/en/services/organ-models/"
                    text="Print 3D Model"
                    action="print"
                  />{' '}
                  <Button
                    buttonClass="button icon solid fa-coffee"
                    url="https://buymeacoff.ee/3DHearter"
                    text="^^"
                    action="donate"
                  />
                </li>
              </ul>
            </div>
          </div>
        </section>
      </article>
    </Layout>
  );
}
